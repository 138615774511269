<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainForm from "@/components/MainForm.vue";
import FormTitle from "@/components/FormTitle.vue";
export default {
	components: {
		FormTitle,
		MainForm,
		SecondaryButton,
	},
	data() {
		return {
			showForm: false,
			listed: false,
			action: "Agregar",
			cultures: [],
			culture: {
				id: { name: "Id", value: null },
				name: { name: "Nombre", value: null },
				answer: { name: "Respuesta", value: null },
				feedback: { name: "Retroalimentación", value: null },
				active: { name: "Activo", value: null },
			},
		};
	},
	created() {
		this.loadCultures();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_CULTURES
			readCultures: "getCultures/readCultures",
			//READ_ERRORS
			getCulturesError: "getCultures/readError",
			createError: "createCulture/readError",
			updateError: "updateCulture/readError",
			deleteError: "deleteCulture/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_CULTURES
			setCreateCulture: "createCulture/SET_CULTURE",
			setUpdateCulture: "updateCulture/SET_CULTURE",
			setUpdateId: "updateCulture/SET_ID",
			setDeleteId: "deleteCulture/SET_ID",
			//SET_ERRORS
			setGetError: "getCultures/SET_ERROR",
			setCreateError: "createCulture/SET_ERROR",
			setUpdateError: "updateCulture/SET_ERROR",
			setDeleteError: "deleteCulture/SET_ERROR",
		}),
		...mapActions({
			getCultures: "getCultures/getCultures",
			createCulture: "createCulture/createCulture",
			updateCulture: "updateCulture/updateCulture",
			deleteCulture: "deleteCulture/deleteCulture",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadCultures() {
			this.listed = false;
			this.clearErrors();
			this.getCultures().then((result) => {
				if (result) {
					this.cultures = this.readCultures;
				}
				this.listed = true;
			});
		},
		toggleForm() {
			this.clearErrors();
			this.showForm = !this.showForm;
		},
		update(data) {
			this.clearErrors();
			this.toggleForm();
			this.action = "Actualizar";
			for (let r in this.culture) {
				this.culture[r]["value"] = data[r];
			}
		},
		remove(culture) {
			this.clearErrors();
			this.setLoading(true);
			this.listed = false;
			this.$swal({
				title: "¿Desea eliminar este Elemento de Cultura?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(culture.id.value || culture.id);
					this.deleteCulture().then((result) => {
						if (result) {
							this.loadCultures();
							this.$swal({
								icon: "success",
								title: `El elemento de Cultura ${culture.name.value || culture.name} ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});
			this.listed = true;
			this.setLoading(false);
		},
		submit() {
			this.clearErrors();
			this.setLoading(true);
			let culture = this.culture;
			let output = {};
			this.listed = false;

			for (let r in culture) {
				output[r] = culture[r].value;
			}

			if (this.action == "Agregar") {
				this.setCreateCulture(output);
				this.createCulture().then((result) => {
					if (result) {
						this.loadCultures();
						this.toggleForm();
						this.$swal({
							icon: "success",
							title: `El Elemento de Cultura ${output.name} ha sido creado con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.clear();
					}
				});
			} else {
				this.setUpdateId(output.id);
				this.setUpdateCulture(output);
				this.updateCulture().then((result) => {
					if (result) {
						this.loadCultures();
						this.toggleForm();
						this.$swal({
							icon: "success",
							title: `El Elemento de Cultura ${output.name} se actualizó con éxito.`,
							showConfirmButton: false,
							timer: 3000,
							
						});
						this.clear();
					}
				});
			}
			this.listed = true;
			this.setLoading(false);
		},
		clear() {
			for (let r in this.culture) {
				this.culture[r].value = null;
			}
			this.action = "Agregar";
		},
		clearErrors() {
			this.setGetError(null);
			this.setCreateError(null);
			this.setDeleteError(null);
			this.setUpdateError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>
				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6"
				/>
				<form-title
					:action="action"
					text="Elemento de "
					textBold="Cultura"
					:name="culture.name.value"
				></form-title>
				<!-- DISPLAY FORM -->
				<form class="grid grid-cols-2 gap-5 items-center">
					<label
						class="col-span-2 text-lg"
						:for="key"
						v-for="(c, key) in culture"
						:key="key"
						v-show="key != 'id'"
					>
						<textarea
							:ref="`input_${key}`"
							:required="key != 'id'"
							class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
							:name="key"
							:placeholder="c.name"
							v-if="key != 'active'"
							v-model="c.value"
							rows="2"
							v-capitalizeInput
						/>
						<div class="flex justify-center items-center" v-if="key == 'active'">
							<span
								class="block text-lg mr-3 flex-none"
								:class="{
									'block text-center': key == 'active',
								}"
								>{{ c.name }}</span
							>
							<input
								:ref="`input_${key}`"
								type="checkbox"
								class="block h-6 px-4 py-2 text-lg bg-gray-light rounded-md flex-none"
								:name="key"
								:placeholder="c.name"
								v-model="c.value"
							/>
						</div>
					</label>
					<span
						v-if="getCulturesError"
						class="block mt-5 red text-center b col-span-2"
						>{{ getCulturesError }}</span
					>
					<span
						v-if="deleteError"
						class="block mt-5 red text-center b col-span-2"
						>{{ deleteError }}</span
					>
					<span
						v-if="createError"
						class="block mt-5 red text-center b col-span-2"
						>{{ createError }}</span
					>
					<span
						v-if="updateError"
						class="block mt-5 red text-center b col-span-2"
						>{{ updateError }}</span
					>
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						<SecondaryButton
							v-if="action == 'Actualizar'"
							@click.native="remove(culture)"
							text="Eliminar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							@click.native="[toggleForm(), clear()]"
							v-else
							text="Cancelar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							:text="action"
							@click.native="submit"
							class="flex-none"
						/>
					</div>
				</form>
			</div>
		</div>
		<main-form
			v-on:toggleForm="toggleForm"
			:action="action"
			text="Elementos de"
			textBold="Cultura"
		></main-form>
		<table
			v-if="!isLoading && cultures.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group elektra-bg-red">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						v-for="(field, key) in culture"
						:key="key"
						v-show="key != 'id'"
					>
						{{ field.name }}
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						cultures.length > 1
							? 'w-full md:w-1/2 lg:w-full'
							: 'w-full'
					"
					v-for="(c, i) in cultures"
					:key="i"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						:class="{ 'green b': key == 'id' }"
						v-for="(field, key) in culture"
						:key="key"
						v-show="key != 'id'"
					>
						<span v-if="key == 'active'">{{
							c[key] ? "Activo" : "Inactivo"
						}}</span>
						<span v-else>{{ c[key] }}</span>
					</td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
								class="title-hint"
								aria-label="Da clic aquí para editar este Elemento de Cultura"
							>
								<font-awesome-icon
									@click="update(c)"
									class="green hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-pen-to-square"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Da clic aquí para eliminar este Elemento de Cultura"
							>
								<font-awesome-icon
									@click="remove(c)"
									class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-trash-can"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron elementos de cultura</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "Nombre", 2 "Respuesta", 3 "Retroalimentación", 4 "Activo";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i + 1}):before {
			content: $field;
		}
	}
}
</style>
